/* Style all font awesome icons */
.fa-wrapper {
  text-align: center;
  font-size: 1em;
  font-family: Roboto, sans-serif;
  border-radius: 2px;
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, .2), 0 2px 2px 0 rgba(0, 0, 0, .14), 0 1px 5px 0 rgba(0, 0, 0, .12);
  transition: background .4s cubic-bezier(.25, .8, .25, 1), box-shadow 280ms cubic-bezier(.4, 0, .2, 1);
  width: 4rem;
  height: 2rem;
  cursor: pointer;
  color: white !important;
}

/* Add a hover effect if you want */
.fa-wrapper:hover {
  opacity: 0.7;
}

