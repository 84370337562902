/* ================================================================================================================== */
/*    Colors                                                                                                          */
/* ================================================================================================================== */

$color1: var(--color1);
$color2: var(--color2);
$color3: var(--color3);
$color4: var(--color4);

/* ================================================================================================================== */
/*    All                                                                                                             */
/* ================================================================================================================== */

@use '@angular/material' as mat;
@import url("https://fonts.googleapis.com/css?family=Work+Sans:400,500,700");
html, body {
  font-family: 'Work Sans', sans-serif;
  scroll-behavior: smooth;
}

a, a:hover {
  text-decoration: underline;
  color: black;
}

ul {
  list-style: none;
}

.card {
  box-shadow: 0 0 5px 0 rgba(204, 204, 204, 0.5);
  border: none;
}

.bold {
  font-weight: bolder !important;
}

.text-underline {
  text-decoration: underline !important;
}

.text-medium {
  font-size: 85%;
}

.clickable {
  cursor: pointer !important;
}

.loading {
  text-align: center;
  font-size: 2.5em;
  margin-top: 10%;
}

.loading > span {
  display: block;
  margin-top: 20px;
}

.bg-custom {
  background-color: var(--color1);
}

.legal-notice {
  font-size: 11px;
  line-height: 12px;
  letter-spacing: -0.19px;
}

/* ================================================================================================================== */
/*    Buttons                                                                                                         */
/* ================================================================================================================== */

.btn-primary, .btn-secondary:active:focus, .btn-primary:disabled {
  background-color: var(--color1);
  border: 2px solid var(--color1);
  color: #fff;
  border-radius: 24px;
}

.btn-secondary:hover {
  background-color: var(--color1);
  border: 2px solid #fff;
  color: #fff;
}

.btn-secondary, .btn-primary:hover, .btn-primary:active:focus, .btn-secondary:disabled {
  background-color: #fff;
  border: 2px solid var(--color1);
  color: var(--color1);
}

.btn-explanation, .btn-explanation:disabled {
  background-color: rgba(255, 255, 255, 0.9) !important;
  color: var(--color3) !important;
}

.btn-explanation:hover, .btn-explanation:active:focus {
  background-color: var(--color3) !important;
  color: #ffffff !important;
}


.btn-rdv, .btn-rdv:disabled {
  background-color: var(--color4) !important;
  border: 2px solid var(--color4) !important;
  color: #FFFFFF !important;
}

.btn-rdv:hover, .btn-rdv:active:focus {
  background-color: #fff !important;
  border: 2px solid var(--color4) !important;
  color: var(--color4) !important;
}

.btn-transparent, .btn-transparent:disabled {
  background-color: transparent !important;
  border: 1px solid #fff !important;
  color: #fff !important;
  font-size: 100%;
}

.btn-transparent:hover, .btn-transparent:active:focus {
  background-color: #fff !important;
  border: 1px solid #fff !important;
  color: #525252 !important;
}

/* ================================================================================================================== */
/*    Angular material input                                                                                          */
/* ================================================================================================================== */

// remove blue blackground in form when autofill
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus textarea:-webkit-autofill,
textarea:-webkit-autofill:hover textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  -webkit-box-shadow: 0 0 0 1000px #FFFFFF inset !important;
}

.carousel-indicators {
  // fix center position for carousel indicators
  margin-left: 0 !important;
  bottom: -13px !important;
}
